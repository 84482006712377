@use '~scss/settings' as *;

.matching-select-schools {
  flex: 1;
  background-color: $color-white;
  padding: 41px 163px;
  border-radius: $border-radius-10;

  h1 {
    @extend %paragraph-subtitle;

    margin-top: 12px;
  }

  > p {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  &__checkbox-field {
    @include flex(column);

    gap: 16px;
    margin-top: 30px;

    > p {
      @extend %paragraph-subtitle-small;

      color: #212121;

      width: calc(100% - 34px);
    }
  }

  .school-card {
    padding: 36px 20px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
      0px 4px 14px 0px rgba(231, 231, 231, 0.05),
      0px 4px 4px 0px rgba(235, 235, 235, 0.08),
      0px 8px 10px 0px rgba(0, 0, 0, 0.04);

    flex: 1;

    &__name {
      @extend %heading4;
    }

    &--wrapper {
      @include flex(row, flex-start, center);

      gap: 16px;
    }
  }

  > button {
    margin-top: 34px;
  }
}

@include phablet() {
  .matching-select-schools {
    padding: 28px;
  }
}

@include laptop() {
  .matching-select-schools {
    padding: 48px;
  }
}
