@use '~scss/settings' as *;
@media (max-width: 800px) {
  .school-group-info-form {
    position: relative;
    margin: 48px;
    min-width: unset;

    &__go-back {
      top: -58px;
      left: -20px;
    }

    &__close {
      top: -58px;
      right: -20px;
    }
  }
}
