@use '~scss/settings' as *;

.component-with-tooltip {
  @include flex(row, center, center);

  gap: 4px;
  color: $color-secondary-light-grey;

  > svg {
    fill: $color-secondary-light-grey;
  }

  .ncoded-checkbox {
    &__box {
      align-self: center;
    }
    &__label {
      @extend %input-descriptive;
    }
  }

  .ncoded-checkbox--disabled {
    .ncoded-checkbox__box {
      color: $color-secondary-light-grey;
    }
  }
}
