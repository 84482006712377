@use '~scss/settings' as *;

.disctrict-schools {
  .state-postcode {
    .s-input-wrapper,
    .s-select-wrapper {
      margin: unset;
      flex: 1;
    }

    @include flex(row, space-between, flex-start);
    @include gap(16px);

    margin: 25px 0;
  }

  > h1 {
    margin-bottom: 24px;
  }
}
