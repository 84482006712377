@use '~scss/settings' as *;

.school-information-stand-alone {
  .fields-row {
    @include flex;
    @include gap(15px);

    > * {
      flex: 1;
    }
  }

  > h1 {
    margin-bottom: 24px;
  }
}
