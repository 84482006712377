@use '~scss/settings' as *;

.tpb-work-availability {
  height: 100%;
  @include flex(column);
  .ncoded-select__options {
    > div img {
      margin-right: 8px;
    }
  }
  &__work-rights {
    @include gap(16px, column);
    > * {
      flex: 1;
    }
    &__radio-group {
      > label[class*='radio-button'] {
        @include flex(row, center, center);

        min-width: 72px;
        border-radius: 68px;

        > span {
          @extend %paragraph;
        }

        > input {
          display: none;
        }
      }
    }
  }
  &__start-date {
    @include flex(column);
    @include gap(8px, column);

    > p:last-of-type {
      @extend %paragraph-descriptive;

      color: $color-text-dark-grey;
    }

    &__inputs {
      @include flex(row, space-between, stretch);
      @include gap(16px);

      > * {
        flex: 1;
      }
    }
  }
}
