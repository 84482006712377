@use '~scss/settings' as *;

.school {
  @include flex(column);

  gap: 16px;

  .error {
    color: $color-error;
  }
}
