@use '~scss/settings' as *;

.job-preview-section {
  @include flex(row, space-around, flex-start);

  gap: 4%;

  &__left,
  &__right {
    flex: 1;
  }

  &__left {
    @include flex(column);

    gap: 8px;
    width: 100%;
    max-width: 340px;

    &__section-number {
      @include font(104px, 142px, 800);

      @extend %opensans;

      color: transparent;
      -webkit-text-stroke: 1px $color-black;
    }

    &__title {
      @include font(40px, 47px, 900);

      @extend %opensans;

      color: $color-primary-dark-blue;
    }
  }

  &__right {
    color: $color-text-dark-grey;
    width: 100%;
    max-width: 540px;

    ul {
      @include flex(column);

      gap: 4px;
      padding-left: 16px;
      list-style-type: unset;

      li {
        @extend %paragraph;
        @extend %opensans;
      }
    }
  }
}
