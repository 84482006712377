@use '~scss/settings' as *;

.finish-filling-vacancy-modal {
  .ncoded-modal__content {
    main {
      overflow: hidden;
    }
  }

  main {
    .ncoded-select {
      margin-top: 32px;
    }
    > p {
      @extend %paragraph-descriptive;
    }
  }
}
