@use '~scss/settings' as *;

.auth-signup {
  display: grid;
  grid-template-columns: 415px 500px;
  grid-template-rows: auto;
  grid-template-areas:
    'a b'
    'a b';
  column-gap: 135px;

  &__titles {
    @include flex(column, center);

    gap: 24px;

    h1 {
      @extend %heading2;

      &.school {
        color: $color-primary-dark-blue;
      }
    }
  }

  .auth-section {
    grid-area: b;
  }
}
