@use '~scss/settings' as *;

.school-part-of-association {
  .description:last-of-type {
    max-width: 70%;

    margin-top: 20px;

    > a {
      color: $color-primary-teal;
      text-decoration: underline;
    }
  }
}
