@use '~scss/settings' as *;

.association-signup {
  main {
    article {
      @include flex(column, center, center);

      gap: 30px;

      > a {
        width: 154px;
        height: 46px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      > p {
        max-width: 350px;
        color: $color-primary-dark-blue;
        text-align: center;
      }

      .auth-form {
        padding: 24px;
        max-width: 440px;
      }
    }
  }
}
