@use '~scss/settings' as *;

.toggle-field {
  @include flex(column);
  @include gap(16px, column);

  > label {
    @extend %paragraph-subtitle-small;
  }

  &__group {
    @include flex(row);
    @include gap(16px);

    > * {
      flex: 1;
    }
    &--reverse {
      @include flex(row-reverse);
      @include gap(0, row);
      @include gap(16px, row-reverse);
    }
  }
}
