@use '~scss/settings' as *;

@media (min-width: 1160px) and (max-width: 1270px) {
  .join-us-signup {
    column-gap: 35px;
    grid-template-columns: 600px 500px;
  }
}

@media (min-width: 768px) and (max-width: 1159px) {
  .join-us-signup {
    grid-template-columns: 600px;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e';
    column-gap: unset;
    row-gap: 50px;
  }
}

@include phablet() {
  .join-us-signup {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e';

    column-gap: unset;
    row-gap: 50px;
    align-items: center;

    .iframe-video-wrapper {
      margin: unset;
    }

    &__titles {
      h1 {
        color: $color-primary-orange;
      }

      h1,
      p {
        text-align: center;
      }
    }

    &__description {
      p {
        text-align: center;
        color: $color-primary-light-teal;
      }

      p:first-of-type {
        color: $color-primary-orange;
      }
    }

    &__why-join-us {
      justify-self: center;

      p,
      ul > li {
        color: $color-primary-light-teal;
      }
    }

    .auth-section {
      justify-self: center;
    }
  }
}

@media (max-width: 580px) {
  .join-us-signup {
    &__titles {
      h1 {
        color: $color-primary-dark-blue;
      }
    }
  }
}
