@use '~scss/settings' as *;

.personal-info {
  @include flex(column);
  @include gap(24px, column);

  &__basic {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    &__phone-number {
      &__hint {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
        margin-top: 4px;
      }
    }
  }

  &__video {
    width: calc(50% - 12px);
  }

  &__video,
  &__photo {
    &__title {
      @include flex(row, flex-start, center);
      @include gap(4px);

      @extend %paragraph-subtitle-small;

      margin-bottom: 15px;

      small {
        margin-left: 4px;
        color: $color-text-light-grey;
      }

      svg {
        fill: $color-secondary-light-grey;
      }
    }
  }
}

.video-tippy {
  .tippy-content {
    div {
      ol {
        padding-left: 24px;
      }
    }
  }
}
