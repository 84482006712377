@use '~scss/settings' as *;

@include laptop {
  .auth-signup {
    max-width: 600px;
    margin: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b';
    row-gap: 32px;
    align-items: center;

    &__titles {
      h1 {
        @include font-size(32px, 37.5px);

        padding-right: 30%;

        + p {
          margin-top: 16px;
        }
      }
    }

    .auth-form {
      button.ncoded-button {
        width: 100%;
      }
    }
  }
}

@include phablet {
  .auth-signup {
    max-width: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'a'
      'b';
    row-gap: 32px;

    &__titles {
      img {
        display: none;
      }

      h1 {
        @include font-size(32px, 37.5px);

        padding-right: 30%;

        + p {
          margin-top: 16px;
        }
      }

      @media (min-width: 520px) {
        h1 {
          color: $color-primary-orange !important;

          + p {
            color: $color-white !important;
          }
        }

        a {
          color: $color-white !important;
        }
      }

      @media (min-width: 570px) {
        h1 {
          color: $color-primary-orange !important;

          + p {
            color: $color-white !important;
          }
        }
      }
    }

    .auth-form {
      button.ncoded-button {
        width: 100%;
      }
    }
  }
}
