@use '~scss/settings' as *;

.matching-pricing-card {
  @include flex(row, flex-start, stretch);

  width: 100%;

  .matching-pricing-card__left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 32px 28px 20px 24px;
    flex: 1.5;
    border-right: unset;

    ul {
      list-style: unset;
      margin-left: 24px;

      li {
        @extend %paragraph-descriptive;

        margin-bottom: 0;
        color: $color-secondary-light-grey;
      }
    }

    a {
      @include flex(row, flex-start, center);
      @extend %paragraph;

      gap: 10px;
      color: $color-primary-dark-blue;
      height: 51px;
      margin-top: 92px;

      svg {
        path {
          fill: $color-primary-dark-blue;
        }
      }
    }
  }

  .matching-pricing-card__right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 32px 28px 20px 24px;
    flex: 1;

    button {
      margin-top: auto;
    }

    &__price {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-light-grey;
    }
  }

  .card {
    p.card__title {
      @extend %paragraph-subtitle-small;

      color: $color-text-dark-grey;
      margin-bottom: 36px;
    }

    p.card__subtitle {
      margin-bottom: 12px;
    }
  }
}
