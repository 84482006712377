@use '~scss/settings' as *;

.association-information {
  @include flex(row, flex-start, center);

  gap: 30px;

  > * {
    flex: 1;
  }
}
