@use '~scss/settings' as *;

.edit-association-information {
  @include flex(column);

  flex: 1;
  min-width: 430px;
  position: relative;
  align-self: center;
  gap: 24px;

  h1 {
    @extend %paragraph-subtitle;
  }

  p {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  button.ncoded-button {
    width: max-content;
    min-width: 120px;
  }

  &__go-back,
  &__close {
    position: absolute;
    top: 0;
    padding: 0;
    min-width: unset;
    min-height: unset;
  }

  &__go-back {
    left: -200px;
  }

  &__close {
    right: -200px;

    > svg {
      @include size(15px);
    }
  }
}
