@use '~scss/settings' as *;

.add-schools-pricing {
  width: 540px;

  .card {
    cursor: default;
  }

  .note {
    @extend %paragraph-descriptive;

    margin-top: 32px;
  }

  &__note {
    @include font(8px, 12px, 300);

    margin: auto 0 24px 0;
    min-height: 48px;
  }

  &__total {
    p {
      &.card {
        &__title,
        &__description {
          margin-bottom: 14px;
        }

        &__hint > svg {
          @include size(16px);
        }
      }
    }

    .ncoded-checkbox__label {
      @extend %paragraph-subtitle-small;
    }
  }

  &__links {
    @include flex(row, flex-start, center);

    @extend %paragraph-subtitle-small;

    gap: 4px;
    color: $color-primary-dark-blue;
  }

  button[type='submit'] {
    margin-top: 24px;
  }
}
