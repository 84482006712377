@use '~scss/settings' as *;

.religion-type {
  * {
    max-width: 340px;
  }

  &__hint {
    @extend %paragraph-descriptive;

    color: $color-secondary-light-grey;
    margin-top: 8px;
  }
}
