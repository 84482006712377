@use '~scss/settings' as *;

.empty-state-add-schools {
  @include flex(column, center, center);

  .template-illustration {
    &__image-container {
      max-width: 174px;
    }

    h1 {
      @include font(27px, 36px, 800);
    }
  }

  button.ncoded-button {
    margin-top: 46px;
  }
}
