@use '~scss/settings' as *;

@include phablet() {
  .searchality-candidate-card {
    &__wrapper {
      overflow: hidden;
      > input {
        display: none;
      }

      > li {
        > section {
          .photo-preview {
            width: 60px;
            height: 60px;
            min-width: 60px;
            margin-right: 12px;

            p {
              flex: 1;
            }
          }
        }
      }

      .ncoded-checkbox {
        margin-right: 8px;
      }
    }
  }
}
