@use '~scss/settings' as *;

.add-schools-total-price {
  p {
    &.card {
      &__title,
      &__description {
        margin-bottom: 14px;
      }

      &__hint {
        margin-top: 12px;
        > svg {
          @include size(16px);
        }
      }
    }
  }
  ul {
    list-style: circle;
    li {
      @extend %paragraph-descriptive;

      color: $color-secondary-light-grey;
      list-style: circle;
      word-break: break-word;
      margin-left: 24px;
      margin-bottom: 8px;
    }
  }
}
