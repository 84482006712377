@use '~scss/settings' as *;

.schools-preview {
  @include flex(column);

  gap: 16px;

  &__school-tags {
    @include flex(row, flex-start, flex-start);

    flex-wrap: wrap;
    gap: 16px;
  }

  button.ncoded-button--outline {
    display: flex;
    padding: 12px 20px;
    border-radius: 87px;

    svg {
      @include size(14px, 14px);
    }
  }
}
