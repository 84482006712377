@use '~scss/settings' as *;

.add-schools-pricing {
  &__cards {
    @include flex();
    @include gap(16px);

    margin-bottom: 16px;
    min-height: 360px;

    .card {
      flex: 1;

      p.card__description {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
      }
    }

    &__left,
    &__right {
      position: relative;

      .pricing__links {
        position: absolute;
        bottom: 16px;
      }
    }

    &__left {
      .pricing-plan__note {
        margin: auto 0 24px 0;
      }

      p {
        &.card__description {
          @include flex(row, flex-start, baseline);

          gap: 8px;
          margin-bottom: 12px;

          svg {
            @include size(8px);

            flex: 0 0 8px;
          }
        }

        &.card__desc {
          margin-bottom: 20px;
        }
      }
    }

    &__right {
      p.card__description {
        margin-bottom: 8px;
      }
    }
  }
}
