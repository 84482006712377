@use '~scss/settings' as *;

.matching-pricing-plan {
  @include flex(column);

  flex: 1;
  background-color: $color-white;
  padding: 83px 180px 134px;
  border-radius: $border-radius-10;

  h1 {
    @extend %paragraph-subtitle;

    margin-top: 12px;
  }

  > p {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  .matching-pricing-card {
    margin-top: 36px;
  }

  > button.ncoded-button--outline {
    margin-top: 24px;
    align-self: flex-end;
  }
}

@include phablet() {
  .matching-pricing-plan {
    padding: 28px;
  }
}

@include laptop() {
  .matching-pricing-plan {
    padding: 48px;
  }
}
