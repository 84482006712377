@use '~scss/settings' as *;

.checkout-new-school {
  max-width: 1080px;

  h1 {
    @extend %paragraph-subtitle;
  }

  > p {
    @extend %paragraph-descriptive;

    margin-top: 8px;
  }

  .checkout-payment {
    margin-top: 42px;
  }
}
