@use '~scss/settings' as *;

.job-application-position-requirements {
  @include flex(column);

  gap: 32px;
  align-self: center;

  &__reverse-toggle {
    > div {
      @include gap(unset);
      @include gap(16px, row-reverse);

      flex-direction: row-reverse;
    }
  }

  &__faith {
    p {
      margin-bottom: 16px;
      width: 70%;
    }
  }

  &__paragraph {
    &__title {
      @include font-size(16px, 19px);

      margin-bottom: 8px;

      span {
        color: $color-primary-teal;
      }
    }

    &__description {
      @extend %paragraph-descriptive;

      margin-bottom: 18px;
    }
  }

  &__start-date {
    &__date-picker {
      margin-top: 20px;
    }

    &__datepicker-label {
      @extend %paragraph-descriptive;

      margin: 20px 0 10px;

      & + div {
        margin-top: 0;
      }
    }
  }

  &__select {
    margin-top: 20px;

    + div {
      margin-top: 20px;
    }
  }

  &__work-rights {
    .job-application-position-requirements__paragraph__description {
      margin-top: 20px;
    }
    &__radio-group {
      @include gap(unset);

      gap: 24px;
      flex-wrap: wrap;

      > label[class*='radio-button'] {
        @include flex(row, center, center);

        min-width: 85px;
        border-radius: 68px;

        > span {
          @extend %paragraph;
        }

        > input {
          display: none;
        }
      }
    }
  }
}
