@use '~scss/settings' as *;

.application-preview {
  > div:first-of-type {
    margin-bottom: 24px;
  }

  > div:last-of-type {
    margin-top: 24px;
  }

  .teacher-application-upload-requested-documents {
    margin-bottom: 24px;
  }

  h4 {
    @extend %paragraph-subtitle;

    margin-bottom: 20px;
  }

  .title-margin-top {
    margin-top: 16px;
  }

  &__detailed-container {
    @include flex(column);

    margin: 16px 0;
    gap: 16px;

    a {
      @include flex(row, flex-start, center);

      @extend %input-descriptive;
      background-color: $color-white;
      gap: 10px;
      color: $color-secondary-light-grey;
      width: fit-content;
      padding: 8px;
      border-radius: 73px;
    }
  }
}
