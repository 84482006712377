$color-white: #fff;
$color-black: #000;

$color-primary-orange: #e75200;
$color-primary-teal: #007078;
$color-primary-light-teal: #bdd8d9;
$color-primary-medium-teal: #58a1a5;
$color-primary-dark-blue: #00416a;

$color-secondary-light-blue: #00acd7;
$color-secondary-grey: #555559;
$color-secondary-light-grey: #768591;
$color-secondary-light-grey-2: #a6a6a6;

$color-text-dark-grey: #212121;
$color-text-light-grey: #a5a5a5;

$color-grey-fc: #fcfcfc;
$color-grey-d6: #d6d6d6;
$color-grey-d9: #d9d9d9;
$color-grey-f2: #f2f2f2;
$color-grey-e6: #e6e6e6;
$color-grey-2b: #2b2b2b;
$color-grey-af: #afafaf;
$color-grey-b7: #b7b7b7;
$color-grey-b5: #b5b5b5;
$color-grey-eb: #ebebeb;
$color-gray-f5: #f5f5f5;
$color-gray-f6: #f6f6f6;
$color-grey-f7: #f7f7f7;
$color-gray-f0: #f0f0f0;
$color-gray-fa: #fafafa;
$color-gray-ea: #eaeaea;
$color-gray-e8: #e8e8e8;

$color-error: #d14343;
$color-required-asterisk: #4d84b6;
$color-anchor: #0091e2;

$color-orange-light: #e59264;
$color-orange-soft: #ffd6bf;
$color-orange-dark: #af5827;

$color-blue-light: #64afe5;

$color-stripe-black: #30313d;
$color-orange-middle: #f0aa83;
