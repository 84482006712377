@use '~scss/settings' as *;

.vacancy-fill-candidate-modal {
  .ncoded-modal {
    &__content {
      width: 800px;
      max-width: 90%;

      main {
        @include flex(column);
      }

      footer {
        @include flex(row, space-between, center);

        padding: 20px 24px;

        .footer-actions {
          @include flex(row, flex-end, center);

          gap: 16px;
        }
      }
    }
  }
}

@include phablet() {
  .vacancy-fill-candidate-modal {
    .ncoded-modal {
      &__content {
        footer {
          @include mobile() {
            @include flex(column, space-between, flex-start);

            gap: 12px;
          }

          .component-with-tooltip {
            gap: 2px;

            > svg {
              @include size(16px);
            }
          }

          .footer-actions {
            gap: 6px;

            button {
              height: 36px;
              padding: 6px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
