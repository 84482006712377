@use '~scss/settings' as *;

.disctrict-schools-preview {
  @include flex(column, center, baseline);
  @include size(100%);

  position: relative;

  > h1 {
    margin-bottom: 24px;
  }

  p.schools-added {
    @extend %paragraph-subtitle-small;

    margin-bottom: 16px;
    color: $color-secondary-light-grey-2;
  }

  .school-tag {
    width: max-content;

    svg {
      @include size(20px, 20px);
    }
  }

  button.ncoded-button--outline {
    display: flex;
    padding: 12px 20px;
    border-radius: 87px;
    margin-top: 12px;

    svg {
      @include size(14px, 14px);

      margin: 0 0 0 15px;
    }
  }

  &__school-tags {
    @include flex(row, flex-start, flex-start);

    flex-wrap: wrap;
    gap: 16px;
  }

  p.description.error {
    color: $color-error;
  }

  p + button[class*='disctrict-schools-preview__submit'] {
    margin-top: unset;
  }
}
