@use '~scss/settings' as *;

.independent-charter-school-group {
  > h1 {
    margin-bottom: 24px;
  }

  p + button[class*='independent-charter-school-group__submit'] {
    margin-top: unset;
  }
}
