@use '~scss/settings' as *;

.join-us-signup {
  display: grid;
  grid-template-columns: 600px 500px;
  grid-template-rows: auto;
  grid-template-areas:
    'a b'
    'c b'
    'd b'
    'e e';
  column-gap: 135px;

  .iframe-video-wrapper {
    grid-area: e;
    width: 100%;
    max-width: 900px;
    margin: 64px auto;
  }

  &__titles {
    h1 {
      @extend %heading2;

      font-size: 36px;
      color: $color-primary-dark-blue;
      margin-bottom: 32px;
      line-height: 1.5;
    }

    p {
      color: $color-primary-orange;
      margin-bottom: 16px;
    }
  }

  &__description {
    p {
      color: $color-primary-dark-blue;
    }

    p:last-of-type {
      margin-top: 16px;
      white-space: break-spaces;
      line-height: 1.8;
    }
  }

  &__why-join-us {
    margin-top: 16px;

    p {
      color: $color-primary-dark-blue;
    }

    ul {
      padding-left: 30px;
      list-style: unset;
      margin-top: 16px;

      li {
        @extend %paragraph;

        color: $color-primary-dark-blue;
      }
    }
  }

  .auth-section {
    grid-area: b;
  }
}
